@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  @apply scroll-smooth text-white;
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4b5563;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*fotn setup*/

@layer base {
  @font-face {
    font-family: "hussar";
    src: url("./assets/font/HussarBold.otf");
  }
  @font-face {
    font-family: "montserrat";
    src: url("./assets/font/MontserratMedium.otf");
  }
  @font-face {
    font-family: "montserrat-bold";
    src: url("./assets/font/MontserratBold.ttf");
  }
  @font-face {
    font-family: "montserrat-black";
    src: url("./assets/font/MontserratExtrabold.ttf");
  }
  @font-face {
    font-family: "montserrat-light";
    src: url("./assets/font/MontserratLight.otf");
  }
  @font-face {
    font-family: "montserrat-semibold";
    src: url("./assets/font/MontserratSemibold.otf");
  }
  @font-face {
    font-family: "montserrat-thin";
    src: url("./assets/font/MontserratThin.ttf");
  }
}

.bg-profile {
  animation: buble 30s ease-in-out infinite;
  background-image: url(./assets/img/img1.webp);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30% 60% 40% 80%/40% 70% 50%;
  position: relative;
}

.rounded-buble {
  animation: buble 15s ease-in-out infinite;
}

.png-shadow {
  filter: drop-shadow(5px 5px 5px rgb(240, 240, 240, 0.4));
}

@keyframes buble {
  0% {
    border-radius: 30% 60% 40% 80%/40% 70% 50%;
  }
  20% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  40% {
    border-radius: 40% 60% 100% 40% 70%;
  }
  60% {
    border-radius: 50% 60% 40% 70%/40% 80% 40% 70%;
  }
  80% {
    border-radius: 60% 30% 20% 80%/70% 50% 30% 40%;
  }
  100% {
    border-radius: 30% 60% 40% 80%/40% 70% 50%;
  }
}

.bg-boxes {
  background-color: #222;
  opacity: 1;
  background-image: linear-gradient(#3a3939 3.1px, transparent 3.1px),
    linear-gradient(to right, #3a3939 3.1px, #222 3.1px);
  background-size: 62px 62px;
}
